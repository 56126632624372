// Generated by Framer (f030ee3)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import Vimeo from "https://framerusercontent.com/modules/0sWquksFr1YDkaIgrl9Z/VgWe6mCMJOseqaLiMnaC/Vimeo.js";
const VimeoFonts = getFonts(Vimeo);

const cycleOrder = ["zBb5TYp2l", "FE488dX9n"];

const serializationHash = "framer-pdL7G"

const variantClassNames = {FE488dX9n: "framer-v-stu26y", zBb5TYp2l: "framer-v-et32uu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "zBb5TYp2l", "Variant 2": "FE488dX9n"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zBb5TYp2l"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zBb5TYp2l", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-et32uu", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zBb5TYp2l"} ref={refBinding} style={{backgroundColor: "rgb(0, 0, 0)", ...style}} {...addPropertyOverrides({FE488dX9n: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-9zgwda-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"sRyIqHXnQ-container"} nodeId={"sRyIqHXnQ"} rendersWithMotion scopeId={"S5PnYzU5Z"}><Vimeo autoplay backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls height={"100%"} id={"sRyIqHXnQ"} isMixedBorderRadius={false} layoutId={"sRyIqHXnQ"} loop={false} mute={false} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} video={"https://vimeo.com/1066887070?share=copy#t=0"} width={"100%"} {...addPropertyOverrides({FE488dX9n: {video: "https://vimeo.com/1069574836?share=copy#t=0"}}, baseVariant, gestureVariant)}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pdL7G.framer-xhape0, .framer-pdL7G .framer-xhape0 { display: block; }", ".framer-pdL7G.framer-et32uu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 800px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1200px; }", ".framer-pdL7G .framer-9zgwda-container { aspect-ratio: 1.7777777777777777 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 675px); position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pdL7G.framer-et32uu { gap: 0px; } .framer-pdL7G.framer-et32uu > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-pdL7G.framer-et32uu > :first-child { margin-left: 0px; } .framer-pdL7G.framer-et32uu > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FE488dX9n":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerS5PnYzU5Z: React.ComponentType<Props> = withCSS(Component, css, "framer-pdL7G") as typeof Component;
export default FramerS5PnYzU5Z;

FramerS5PnYzU5Z.displayName = "video-overlay-testimonial";

FramerS5PnYzU5Z.defaultProps = {height: 800, width: 1200};

addPropertyControls(FramerS5PnYzU5Z, {variant: {options: ["zBb5TYp2l", "FE488dX9n"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerS5PnYzU5Z, [{explicitInter: true, fonts: []}, ...VimeoFonts], {supportsExplicitInterCodegen: true})